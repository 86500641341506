import styles from "./Warscroll.module.scss";

const calcSaves = (wounds, save, ward) => {
    const saveProb = (save && save <= 6)? 1 - ((7 - save) / 6) : 1;
    const wardProb = (ward && ward <= 6) ? 1 - ((7 - ward) / 6) : 1;
    return Math.round(wounds * (1 / saveProb) * (1 / wardProb))
}
const EffectiveHealth = ({scroll, reinforced}) => {
    const wounds = reinforced ? scroll.health * scroll.modelCount *2: scroll.health * scroll.modelCount
    return <table className={styles.health}>
        <thead>
        <tr>
            <th>MW</th>
            <th>Rend 3</th>
            <th>Rend 2</th>
            <th>Rend 1</th>
            <th>No Rend</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{calcSaves(wounds, 0, parseInt(scroll.ward || 0))}</td>
            <td>{calcSaves(wounds, parseInt(scroll.save) + 3, parseInt(scroll.ward || 0))}</td>
            <td>{calcSaves(wounds, parseInt(scroll.save) + 2, parseInt(scroll.ward || 0))}</td>
            <td>{calcSaves(wounds, parseInt(scroll.save) + 1, parseInt(scroll.ward || 0))}</td>
            <td>{calcSaves(wounds, parseInt(scroll.save), parseInt(scroll.ward || 0))}</td>
        </tr>
        </tbody>
    </table>
}

export default EffectiveHealth