import styles from "./Warscroll.module.scss";
import {
    Autocomplete,
    IconButton,
    InputLabel,
    TextField,
    useMediaQuery
} from "@mui/material";
import {useState} from "react";
import Warscroll from "./Warscroll";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Brightness4, Brightness7} from "@mui/icons-material";
import * as React from "react";

const Battlescroll = ({warscrollData, prevData}) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [scrollIds, setScrollIds] = useState([]);
    const [faction, setFaction] = useState("");
    const [darkMode, setDarkMode] = useState(prefersDarkMode);

    const handleChangeFaction = (value) => {
        setFaction(value)
        const scrollIds = warscrollData
            .filter(s=>s.faction.includes(value))
            .map(s=>s.id)
            .filter(sid => JSON.stringify(warscrollData.find(s=>s.id === sid)) !== JSON.stringify(prevData.find(s=>s.id===sid)));
        setScrollIds(scrollIds)
    }

    const factionOptions = [...new Set(warscrollData.flatMap(scroll => scroll.faction))]
        .filter(f => !f.includes("Grand Alliance"))
        .sort()

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    }

    const darkTheme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            ...(darkMode ? {
                info: {main: "rgba(255,204,0,0.27)"}
            } : {
                info: {main: "#d7cfc4"}
            })
        },
    });

    return <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <div className={styles.app}>
            <div className={styles.select + " " + styles.noPrint}>
                <div className={styles.selectGroup}>
                    <InputLabel id="faction-label">Faction</InputLabel>
                    <Autocomplete
                        value={faction || null}
                        id={"faction"}
                        renderInput={(params) => <TextField {...params} placeholder={"Faction"}/>}
                        onChange={(event, value) => {
                            handleChangeFaction(value);
                        }}
                        options={factionOptions}
                    />
                </div>
            </div>
            <h2>New on left</h2>
            {scrollIds.map(sid => <div style={{display:"flex"}}>
                {warscrollData.find(s=>s.id === sid)?<Warscroll scroll={warscrollData.find(s => s.id === sid)}/>:null}
                {prevData.find(s=>s.id === sid)?<Warscroll scroll={prevData.find(s => s.id === sid)}/>:null}
            </div>)}
            <div className={styles.noPrint + " " + styles.heading}>
                <IconButton sx={{ml: 1}} onClick={toggleDarkMode} color="inherit">
                    {darkMode ? <Brightness7/> : <Brightness4/>}
                </IconButton>
            </div>

        </div>
    </ThemeProvider>;
}

export default Battlescroll