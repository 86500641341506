import styles from './Warscroll.module.scss';
import Abilities from "./Abilities";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Container,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Weapons from "./Weapons";
import {replaceNewLines} from "./Warscrolls";
import {Casino} from "@mui/icons-material";
import {useState} from "react";
import EffectiveHealth from "./EffectiveHealth";


const Warscroll = ({scroll}) => {
    let [reinforce, setReinforce] = useState(0);
    let [hitChange, setHitChange] = useState(0);
    let [woundChange, setWoundChange] = useState(0);
    let [rendChange, setRendChange] = useState(0);
    let [showHealthCalc, setShowHealthCalc] = useState(0);
    let [math, setMath] = useState();
    let modifiers = {reinforce, hitChange, woundChange, rendChange, math}
    const mathToggle = (e, v) => {
        setMath(v)
        setReinforce(0)
        setHitChange(0)
        setWoundChange(0)
        setRendChange(0)
        setShowHealthCalc(0)
    }
    return <Container sx={{bgcolor: 'info.main', margin: "5px", borderRadius: 5, border: 1}} key={scroll.id}>
        <div className={styles.top}>
            {scroll.image ? <div className={styles.left + " " + styles.img}>
                <Card>
                    <CardMedia
                        component="img"
                        height={"300"}
                        image={scroll.image}
                        alt={scroll.name}
                    />
                </Card>
            </div> : null}
            <div className={styles.right}>
                <div className={styles.banner}>
                    <h1>{scroll.name}{scroll.isLegends ? " - Legend" : null}</h1>
                    {scroll.weapons.length > 0 ? <ToggleButtonGroup value={math}
                                                                    onChange={mathToggle}
                                                                    exclusive
                                                                    sx={{marginLeft: "auto"}}>
                        <ToggleButton value={"math"}><Casino/></ToggleButton>
                    </ToggleButtonGroup> : null}
                </div>

                <div className={styles.top}>
                    {scroll.move ? <Card sx={{margin: "5px"}}>
                        <CardContent>
                            <Typography variant="h4" align={"center"} component="div">
                                {scroll.move}
                            </Typography>
                        </CardContent>
                        <CardHeader title={"Move"}/>
                    </Card> : null}
                    {scroll.health ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.health}</Typography></CardContent>
                            <CardHeader title="Health"/>
                        </Card> : null}
                    {scroll.save ? <Card sx={{margin: "5px"}}>
                        <CardContent> <Typography variant="h4" align={"center"}
                                                  component="div">{scroll.save}</Typography></CardContent>
                        <CardHeader title="Save"/>
                    </Card> : null}
                    {scroll.ward ? <Card sx={{margin: "5px"}}>
                        <CardContent> <Typography variant="h4" align={"center"}
                                                  component="div">{scroll.ward}</Typography></CardContent>
                        <CardHeader title="Ward"/>
                    </Card> : null}
                    {scroll.wizardLevel ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.wizardLevel}</Typography></CardContent>
                            <CardHeader title="Wizard"/>
                        </Card> : null}
                    {scroll.priestLevel ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.priestLevel}</Typography></CardContent>
                            <CardHeader title="Priest"/>
                        </Card> : null}
                    {scroll.control ?
                        <Card sx={{margin: "5px"}}>
                            <CardContent> <Typography variant="h4" align={"center"}
                                                      component="div">{scroll.control}</Typography></CardContent>
                            <CardHeader title={scroll.keywords.includes("Manifestation") ? "Banishment" : "Control"}/>
                        </Card> : null}
                    {math ?
                        <div className={styles.controls}>
                            {scroll.modelCount > 1 && !scroll.notes?.includes("cannot be reinforced") ?
                                <ToggleButtonGroup value={reinforce}
                                                   size={"small"}
                                                   sx={{margin: "5px"}}
                                                   exclusive
                                                   onChange={(e, v) => setReinforce(v)}>
                                    <ToggleButton value={true}>Reinforce</ToggleButton>
                                </ToggleButtonGroup> : null}
                            <ToggleButtonGroup value={hitChange}
                                               size={"small"}
                                               sx={{margin: "5px"}}
                                               exclusive
                                               onChange={(e, v) => setHitChange(v)}>
                                <ToggleButton value={-1}>+1 hit</ToggleButton>
                                <ToggleButton value={1}>-1 hit</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup value={woundChange}
                                               size={"small"}
                                               exclusive
                                               sx={{margin: "5px"}}
                                               onChange={(e, v) => setWoundChange(v)}>
                                <ToggleButton value={-1}>+1 wound</ToggleButton>
                                <ToggleButton value={1}>-1 wound</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup value={rendChange}
                                               size={"small"}
                                               exclusive
                                               sx={{margin: "5px"}}
                                               onChange={(e, v) => setRendChange(v)}>
                                <ToggleButton value={1}>+1 rend</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup value={showHealthCalc}
                                               size={"small"}
                                               exclusive
                                               sx={{margin: "5px"}}
                                               onChange={(e, v) => setShowHealthCalc(v)}>
                                <ToggleButton value={1}>show effective health</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        : null}
                </div>
                {showHealthCalc ? <div className={styles.right}>
                        <EffectiveHealth scroll={scroll} reinforced={reinforce}/>
                    </div>
                    : null}
                <div className={styles.right + " " + styles.weapons}>
                    <Weapons weapons={scroll.weapons.filter(weapon => weapon.type === "ranged")}
                             keywords={scroll.keywords}
                             squadSize={scroll.modelCount}
                             modifications={modifiers}
                             type={"Ranged Weapons"}/>
                    <Weapons weapons={scroll.weapons.filter(weapon => weapon.type === "melee")}
                             keywords={scroll.keywords}
                             squadSize={scroll.modelCount}
                             modifications={modifiers}
                             type={"Melee Weapons"}/>
                </div>
            </div>
        </div>

        <div className={styles.main}>
            <Abilities abilities={scroll.abilities} wargear={
                scroll.wargearOptionsText ? <Card className={styles.wargear}>
                    <CardHeader title={"Wargear"}/>
                    <CardContent>{replaceNewLines(scroll.wargearOptionsText)}</CardContent>
                </Card> : null}/>

            <div>
                {scroll.keywords.length > 0 ?
                    <Container sx={{borderColor: 'secondary.main', border: 1}}>
                        <div><strong>KEYWORDS </strong></div>
                        <div className={styles.words}> {scroll.keywords.join(", ")}</div>
                    </Container> : null}
                <div>
                    {scroll.points ? <div>Points: {reinforce ? scroll.points * 2 : scroll.points}</div> : null}
                    {scroll.modelCount ?
                        <div>Model Count: {reinforce ? scroll.modelCount * 2 : scroll.modelCount}</div> : null}
                    {scroll.baseSize ? <div>Basesize : {scroll.baseSize}</div> : null}
                    {scroll.notes ? <div>Notes: {scroll.notes}</div> : null}
                </div>
            </div>
        </div>

    </Container>;
}

export default Warscroll